.serve {
    display: flex;
    gap: 1rem;
}

.serve>div>p {
    color: var(--gray);
}

.serve-blur {
    width: 20rem;
    height: 20rem;
    right: 0;
}