.book-container {
  display: flex;
  gap: 2rem;
}

.book-blur {
  width: 20rem;
  height: 20rem;
  left: 0;
}

.left-b {
  display: flex;
}

.left-b > img {
  border-radius: 20px;
  width: fit-content;
  height: fit-content;
}

.right-b {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.right-b > div {
  display: flex;
}

.right-b > :last-child {
  justify-content: space-between;
}
.right-b > :last-child .arrows {
  display: flex;
  gap: 2rem;
}

.right-b > :last-child .arrows>span {
    background-color: white;
    padding: .75rem;
    border-radius: 50%;
}

.right-b > :last-child .arrows>span:hover {
    background-color: var(--primaryColor);
    color: white;
    cursor: pointer;
}
