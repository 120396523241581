.header {
  position: relative;
}

.header-blur {
  width: 40rem;
  height: 20rem;
  left: 0;
}

.menu {
  display: flex;
  padding: 2rem 0rem;
  justify-content: space-between;
}

.left-m {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.logo {
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
}

.menu-h {
  list-style: none;
  display: flex;
  gap: 2rem;
}

.menu-h > li {
  cursor: pointer;
  font-weight: bold;
}

.menu-h > li:hover {
  color: var(--primaryColor);
}

.right-m {
  flex: 1;
}

.header-brief {
  display: flex;
  margin-top: 2rem;
  justify-content: space-between;
  gap: 2rem;
}

.left-b {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: center;
  gap: 2rem;
}

.left-b > span {
  background-color: var(--primaryColor);
  color: white;
  font-weight: bold;
  width: fit-content;
  padding: 1rem;
  border-radius: 2rem;
}

.video-h {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  position: relative;
  padding: 1rem;
  box-shadow: .5px .5px 5px var(--primaryColor);
  width: fit-content;
}

.video-h > img {
  width: 20rem;
  height: 10rem;
}

.video-h > div {
  position: absolute;
}

.video-h > div > img {
  width: 2rem;
  height: 2rem;
}

.right-b {
  display: flex;
  flex: 2;
  position: relative;
}

.right-b > img {
  width: 35rem;
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -4rem;
  top: -2rem;
  z-index: -1;
  width: 30rem;
  height: 30rem;
  border-radius: 50%;
  box-shadow: .5px .5px 5px var(--primaryColor);
  background-attachment: white;
}

.circle>img:hover {
  cursor: pointer;
}

.circle > img {
  width: 29.5rem;
  height: 29.5rem;
  border-radius: 50%;
}
