.destination-container {
    display: flex;
    flex-direction: column;
}

.destination-container>:nth-child(2) {
    display: flex;
    justify-content: space-between;
}

.destination-blur {
    width: 40rem;
    height: 20rem;
    right: 0;
  }

.right-d {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.right-d>div {
    padding: .40rem;
    border-radius: 50%;
    background-color: var(--gray);
    height: fit-content;
    cursor: pointer;
}

.destination {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
}

.destination>div {
    display: flex;
    flex-direction: column;
    width: 31%;
}

.destination>div>img {
    height: 100%;
    border-radius: 20px;
}

.destination>div>span {
    color: var(--gray);
    margin-bottom: 5px;
}

.destination>div>:last-child {
    display: flex;
    justify-content: space-between;
}

.destination>div>:last-child>:first-child {
    display: inline-block;
    padding: 2px 10px;
    border-radius: 10px;
    height: fit-content;
    background-color: var(--primaryColor);
    color: white;
}
