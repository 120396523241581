.footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    background-color: white;
    padding: 2rem 0rem;
}

.footer-blur {
    width: 20rem;
    height: 20rem;
    left: 0;
    z-index: 2;
}

.footer-container>p {
    display: flex;
    gap: 1rem;
}
.footer-container>p>span {
    color: var(--primaryColor);
}

.social-media {
    display: flex;
    gap: 2rem;
}

.social-media>img {
    display: flex;
    width: 3rem;
}